import {
  defineNuxtRouteMiddleware,
  navigateTo,
} from 'nuxt/app';
import { useAuthStore } from '@stores/useAuthStore';

export default defineNuxtRouteMiddleware(async () => {
  const { hasIbans } = toRefs(useAuthStore());

  if (hasIbans) {
    return navigateTo({ path: '/mijn-profiel' });
  }
});
